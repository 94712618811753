import React, { useState, useCallback, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';
import {
	Box,
	Button,
	Image,
	Input,
	Text,
	Heading,
	useToast,
} from '@chakra-ui/react';
import { saveAs } from 'file-saver';
import uploadIcon from '../../../assets/upload_icon.png';
import AutoTypeText from '../multistepform/autotype';
import { useSelector } from 'react-redux';
import BankPaginatedData from './bankPaginatedData';

const Cash = () => {
	const toast = useToast();
	const [data, setData] = useState([]);
	const [editing, setEditing] = useState(false);
	const companyId = useSelector((state) => state.companyId);
	const selectedLocation = useSelector((state) => state.selectedLocation);
	const selectedBranch = useSelector((state) => state.selectedBranch);
	const locationCode = useSelector((state) => state.locationCode);
	const [uploadMessageExcel, setUploadMessageExcel] = useState(undefined);
	const [errors, setErrors] = useState([]);
	const [hasValidationErrors, setHasValidationErrors] = useState(false);
	const [errorValue, setErrorValue] = useState();
	const [totalOpenBalance, setTotalOpenBalance] = useState(0);
	const [bankList, setBankList] = useState([]);
	const [pagesWithErrors, setPagesWithErrors] = useState(new Set());
	const [currentPage, setCurrentPage] = useState(1); // Current page
	const itemsPerPage = 50; // Number of items to display per page

	function toastCallRequestError() {
		return toast({
			title: 'Request Faild',
			status: 'error',
			duration: 3000,
			isClosable: true,
		});
	}

	function bigPrint(number) {
		const numberAsString = number.toString();
		return numberAsString;
	}

	function formatDateToDdMmYy(date) {
		const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if needed
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (add 1 as months are zero-indexed) and pad with leading zero if needed
		const year = String(date.getFullYear()).slice(-2); // Get the last 2 digits of the year

		return `${day}-${month}-${year}`;
	}
	const currentDate = new Date();
	const formattedDate = formatDateToDdMmYy(currentDate);

	useEffect(() => {
		const glData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}cash-list.php?company_id=${companyId}&branch_id=${selectedBranch}&location_id=${selectedLocation}`,
					{
						method: 'GET',
					}
				);
				if (response.ok) {
					const data = await response.json();
					setBankList(data);
				}
			} catch {
				console.log('error');
			}
		};
		glData();
	}, [selectedBranch]);

	useEffect(() => {
		// Calculate the errorValue based on the errors state
		const newErrorValue = errors.reduce((acc, rowErrors) => {
			return (
				acc +
				Object.values(rowErrors).filter((error) => error !== null)
					.length
			);
		}, 0);

		setErrorValue(newErrorValue);
	}, [errors]); // Watch for changes in the errors state

	const handleFileDrop = useCallback((acceptedFiles) => {
		const file = acceptedFiles[0];
		const reader = new FileReader();

		reader.onload = (event) => {
			const data = new Uint8Array(event.target.result);
			const workbook = XLSX.read(data, { type: 'array' });

			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];

			const excelData = XLSX.utils.sheet_to_json(worksheet, {
				header: 1,
			});

			const headers = excelData[0].map((header) => header.trim());
			const formattedData = excelData.slice(1).map((row) => {
				const rowData = {};
				row.forEach((cell, columnIndex) => {
					const header = headers[columnIndex];
					rowData[header] = cell;
				});
				return rowData;
			});

			// Fill missing keys with empty strings
			const emptyRow = Object.fromEntries(
				headers.map((header) => [header, ''])
			);
			const dataWithEmptyKeys = formattedData.map((row) => ({
				...emptyRow,
				...row,
			}));

			// Filter out blank rows
			const nonBlankRows = dataWithEmptyKeys.filter((row) => {
				return Object.values(row).some(
					(cellValue) => cellValue !== null && cellValue !== ''
				);
			});
			setData(nonBlankRows);
			setUploadMessageExcel(undefined);
		};

		reader.readAsArrayBuffer(file);
	});

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: handleFileDrop,
		accept: '.xlsx',
	});

	const demoXlSX = bankList?.message;
	function generateCSVData(demoXlSX) {
		const headers = Object.keys(demoXlSX[0]); // Get the keys from the first object as headers
		const headerRow = headers.join(',') + '\n'; // CSV header row

		const rows = demoXlSX
			.map((item) => {
				const values = headers.map((header) => item[header]);
				return values.join(',');
			})
			.join('\n');

		return headerRow + rows;
	}
	const handleDownload = () => {
		try {
			const updatedData = demoXlSX?.map((asset) => ({
				...asset,
				open_balance: '',
			}));
			const csvData = generateCSVData(updatedData);
			const blob = new Blob([csvData], {
				type: 'text/csv;charset=utf-8',
			});
			saveAs(
				blob,
				`Bank-Cash-Balance-${locationCode}-${formattedDate}.csv`
			);
		} catch {
			toastCallRequestError();
		}
	};

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	function bigPrint(number) {
		const numberAsString = number.toString();
		return numberAsString;
	}

	const isParentValues = bigPrint(totalOpenBalance) <= bankList?.parentCashGl;

	return (
		<Box
			width='100%'
			height='100%'
			display='flex'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'>
			<Box w='100%' display='flex' justifyContent='space-around'>
				<Box
					width='38%'
					display='flex'
					justifyContent='space-around'
					alignItems='center'>
					<Box width='400px'>
						<Box
							border='2px dashed var(--chakra-colors-bgGraycolor)'
							p='15px'
							borderRadius='20px'
							opacity={editing ? '0.5' : '1'}
							pointerEvents={editing ? 'none' : 'painted'}>
							<Box
								{...getRootProps()}
								bg='var(--chakra-colors-mainBlueColor)'
								width='100%'
								height='100%'
								borderRadius='20px'
								display='flex'
								justifyContent='center'
								alignItems='center'
								color='white'
								padding='50px 0px'
								cursor='grab'>
								<Input {...getInputProps()} />
								{isDragActive ? (
									<Box
										display='flex'
										flexDirection='column'
										justifyContent='center'
										alignItems='center'>
										<Image
											src={uploadIcon}
											alt='upload icon'
											height='100px'
											width='100px'
										/>
										<Text>Drop the Excel file here...</Text>
									</Box>
								) : (
									<Box
										display='flex'
										flexDirection='column'
										justifyContent='center'
										alignItems='center'>
										<Image
											src={uploadIcon}
											alt='upload icon'
											height='100px'
											width='100px'
										/>
										<Text>Drag & Drop your files</Text>
									</Box>
								)}
							</Box>
						</Box>
						<Box
							display='flex'
							width='100%'
							justifyContent='center'
							gap='15px'
							paddingTop='45px'>
							<Button
								borderRadius='10px'
								border='1px solid #003060'
								p='20px 30px'
								width='50%'
								fontSize='15px'
								background='none'
								color='mainBlueColor'
								onClick={handleDownload}
								cursor='pointer'>
								Download Sample
							</Button>
						</Box>
					</Box>
				</Box>

				<Box
					width='58%'
					boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
					borderRadius='15px'
					padding='15px'>
					<Heading
						mb='15px'
						color='var(--chakra-colors-mainBlueColor)'
						fontSize='28px'>
						Data Summary :{' '}
					</Heading>
					<Text
						fontSize='1.7rem'
						fontWeight='600'
						marginBottom='10px'
						color='green'>
						Total lines : {data?.length}
					</Text>
					<Text
						fontSize='1.7rem'
						fontWeight='600'
						marginBottom='10px'
						color='green'>
						Parent Value: {bankList?.parentCashGl}
					</Text>
					<Text
						fontSize='1.7rem'
						fontWeight='600'
						marginBottom='10px'
						color='red'>
						Total Error : {errorValue}
					</Text>
					{!isParentValues && (
						<Text
							fontSize='1.7rem'
							fontWeight='600'
							marginBottom='10px'
							color='red'>
							Warning: Total balance can't be greter than parent
							value
						</Text>
					)}
					{/* Display the total OPEN_BALANCE */}
					<Text
						fontSize='1.7rem'
						fontWeight='600'
						color='mainBlueColor'
						marginBottom='5px'>
						Balance: {bigPrint(totalOpenBalance)}
					</Text>
					<Box mt='10px' mb='10px'>
						<Box
							fontSize='1.7rem'
							fontWeight='600'
							color='mainBlueColor'>
							Pages with errors:
						</Box>

						{Array.from(pagesWithErrors).join(', ')}
					</Box>
					<Box>
						<Text
							fontSize='1.7rem'
							fontWeight='600'
							color='mainBlueColor'
							marginBottom='5px'>
							Data Review Report -
						</Text>
						<AutoTypeText />
					</Box>
				</Box>
			</Box>
			<BankPaginatedData
				data={data}
				setData={setData}
				currentPage={currentPage}
				itemsPerPage={itemsPerPage}
				editing={editing}
				setEditing={setEditing}
				onPageChange={handlePageChange}
				setTotalOpenBalance={setTotalOpenBalance}
				setErrorValue={setErrorValue}
				errors={errors}
				setErrors={setErrors}
				hasValidationErrors={hasValidationErrors}
				setHasValidationErrors={setHasValidationErrors}
				setPagesWithErrors={setPagesWithErrors}
				uploadMessageExcel={uploadMessageExcel}
				setUploadMessageExcel={setUploadMessageExcel}
				isParentValues={isParentValues}
			/>
		</Box>
	);
};

export default Cash;