import React from 'react';
import {
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	TabIndicator,
} from '@chakra-ui/react';
import GlValue from './glvalue';
import CustomerBalance from './customer';
import Vendor from './vendor';
import ItemValue from './itemvalue';
import OpenPo from './openpo';
import OpenSo from './openso';
import Bank from './bank';
import Demo from './subLedger';
import Cash from './cash';

const Transaction = () => {
	return (
		<>
			<Tabs position='relative' variant='unstyled' w='100%'>
				<TabList
					sx={{
						'& .chakra-tabs__tab': {
							borderRadius: '15px 15px 0px 0px',
							border: 'none',
							color: 'var(--chakra-colors-mainBlueColor)',
							fontSize: '1.6rem',
							fontWeight: '700',
							pb: '10px',
							pt: '10px',
						},
						'& .chakra-tabs__tab[aria-selected=true]': {
							borderRadius: '15px 15px 0px 0px',
							color: 'white',
							bg: 'var(--chakra-colors-mainBlueColor)',
						},
					}}>
					<Tab>GL Value</Tab>
					<Tab>Customer Balance</Tab>
					<Tab>Vendor Balance</Tab>
					{/* <Tab>Bank Cash Balance</Tab> */}
					<Tab>Bank</Tab>
					<Tab>Cash</Tab>
					<Tab>SubLedger</Tab>
					<Tab>Stock</Tab>
					<Tab>Open PO</Tab>
					<Tab>Open SO</Tab>
				</TabList>
				<TabIndicator
					mt='-1.5px'
					height='2px'
					bg='var(--chakra-colors-mainBlueColor)'
					borderRadius='1px'
				/>
				<TabPanels
					minHeight='calc(100vh - 221px)'
					sx={{
						'& .chakra-tabs__tab-panel': {
							display: 'flex',
							alignItems: 'center',
							minHeight: '485px',
							boxShadow: '1px 1px 3px rgba(0,0,0,0.3)',
						},
					}}>
					<TabPanel>
						<GlValue />
					</TabPanel>
					<TabPanel p='0px'>
						<CustomerBalance />
					</TabPanel>
					<TabPanel p='0px'>
						<Vendor />
					</TabPanel>
					<TabPanel p='0px'>
						<Bank />
					</TabPanel>
					<TabPanel p='0px'>
						<Cash />
					</TabPanel>
					<TabPanel p='0px'>
						<Demo />
					</TabPanel>
					<TabPanel>
						<ItemValue />
					</TabPanel>
					<TabPanel>
						<OpenPo />
					</TabPanel>
					<TabPanel>
						<OpenSo />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</>
	);
};

export default Transaction;
