import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, Input, useToast, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

const CustomerInvoicePaginatedData = ({
	data,
	setData,
	currentPage,
	itemsPerPage,
	editing,
	setEditing,
	onPageChange,
	setTotalOpenBalance,
	setGroupedSums,
	setErrorValue,
	setErrors,
	setHasValidationErrors,
	setPagesWithErrors,
	uploadMessageExcel,
	setUploadMessageExcel,
	isParentGlValues,
	customerList
}) => {
	const toast = useToast();
	const [isLoading, setIsLoading] = useState(false);
	const [jumpPage, setJumpPage] = useState(1); // Initialize jump page as page 1
	const [inputValues, setInputValues] = useState({}); // Object to store input values
	const companyId = useSelector((state) => state.companyId);
	const userId = useSelector((state) => state.userId);
	const selectedLocation = useSelector((state) => state.selectedLocation);
	const selectedBranch = useSelector((state) => state.selectedBranch);
	const [totalPages, setTotalPages] = useState(1);
	const [rowValidationErrors, setRowValidationErrors] = useState([]);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = Math.min(startIndex + itemsPerPage, data.length);
	const currentData = data.slice(startIndex, endIndex);

	const apiCustomerListData = customerList?.message;

	function toastCall(data) {
		return toast({
			title: data?.message,
			status: data?.status,
			duration: 3000,
			isClosable: true,
		});
	}

	function isValidDate(input) {
		const datePattern = /^\d{2}-\d{2}-\d{4}$/;
		return datePattern.test(input);
	}
	function isValidNumber(input) {
		const numberPattern = /^-?[0-9]\d*(\.\d+)?$/;
		return numberPattern.test(input);
	}
	function isValidText(input) {
		const textPattern = /^[A-Za-z0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/'/-]+$/;
		return textPattern.test(input);
	}
	function isValidVarchar(input) {
		// Allow null or blank spaces by using a modified regular expression
		const textPattern =
			/^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1})?$/;
		return textPattern.test(input);
	}
	// Calculate the total number of pages when data or itemsPerPage changes
	useEffect(() => {
		const newTotalPages = Math.ceil(data.length / itemsPerPage);
		setTotalPages(newTotalPages);
	}, [data, itemsPerPage]);

	// Effect to update inputValues when currentPage changes
	useEffect(() => {
		if (editing) {
			// Initialize inputValues with the current page's data
			const initialInputValues = {};
			const startIndex = (currentPage - 1) * itemsPerPage;
			const endIndex = Math.min(startIndex + itemsPerPage, data.length);
			const currentData = data.slice(startIndex, endIndex);

			currentData.forEach((row, rowIndex) => {
				Object.keys(row).forEach((header) => {
					initialInputValues[`${rowIndex}-${header}`] = row[header];
				});
			});

			setInputValues(initialInputValues);
		} else {
			// Clear inputValues when not in editing mode
			setInputValues({});
		}
	}, [editing, currentPage, data, itemsPerPage]);

	// Update rowValidationErrors when currentPage changes
	useEffect(() => {
		// Extract the data for the current page
		const currentData = data.slice(startIndex, endIndex);

		// Calculate row validation errors for the current page
		const newErrors = currentData.map(() => ({}));

		currentData.forEach((row, rowIndex) => {
			Object.keys(row).forEach((header) => {
				const newValue =
					inputValues[`${rowIndex}-${header}`] || row[header];
				let error = null;

				if (header === 'customer_code') {
					if (!isValidNumber(newValue)) {
						error = 'Invalid number input';
					}
				} else if (header === 'inv_date') {
					if (!isValidDate(newValue)) {
						error = 'Invalid date format (DD-MM-YYYY)';
					}
				} else if (header === 'open_balance') {
					if (!isValidNumber(newValue)) {
						error = 'Invalid number input';
					}
				} else if (header === 'customer_gstin') {
					if (!isValidVarchar(newValue)) {
						error = 'Invalid VarChar format';
					}
				} else if (header === 'credit_period') {
					if (!isValidNumber(newValue)) {
						error = 'Invalid number input';
					}
				} else if (header === 'sub_total') {
					if (!isValidNumber(newValue)) {
						error = 'Invalid number input';
					}
				} else if (header === 'conversion_rate') {
					if (!isValidNumber(newValue)) {
						error = 'Invalid number input';
					}
				} else if (header === 'discount') {
					if (
						newValue !== '' &&
						newValue !== ' ' &&
						newValue !== null &&
						!isValidNumber(newValue)
					) {
						error = 'Invalid number input';
					}
				} else if (header === 'cgst') {
					if (!isValidNumber(newValue)) {
						error = 'Invalid number input';
					}
				} else if (header === 'sgst') {
					if (!isValidNumber(newValue)) {
						error = 'Invalid number input';
					}
				} else if (header === 'igst') {
					if (!isValidNumber(newValue)) {
						error = 'Invalid number input';
					}
				} else if (header === 'total_amt') {
					if (!isValidNumber(newValue)) {
						error = 'Invalid number input';
					}
				} else if (header === 'customer_shipping_address') {
					// Allow blank string or null as valid values
					if (
						newValue !== '' &&
						newValue !== ' ' &&
						newValue !== null &&
						!isValidText(newValue)
					) {
						error = 'Invalid text input';
					}
				} else if (header === 'customer_billing_address') {
					// Allow blank string or null as valid values
					if (
						newValue !== '' &&
						newValue !== ' ' &&
						newValue !== null &&
						!isValidText(newValue)
					) {
						error = 'Invalid text input';
					}
				} else if (header === 'due_amt') {
					if (!isValidNumber(newValue)) {
						error = 'Invalid number input';
					}
				} else {
					if (!isValidText(newValue)) {
						error = 'Invalid text input';
					}
				}

				newErrors[rowIndex][header] = error; // Set the error for this header
			});
		});

		// Update the rowValidationErrors state for the current page
		setRowValidationErrors(newErrors);
	}, [currentPage, data, inputValues, startIndex, endIndex]);

	function calculateTotalAmt() {
		const updatedTotalAmt = data.reduce((total, row) => {
			const totalAmt = parseFloat(row['due_amt']);
			return isNaN(totalAmt) ? total : total + totalAmt;
		}, 0);

		setTotalOpenBalance(updatedTotalAmt);
	}

	const handleEdit = useCallback(() => {
		if (!editing) {
			const newErrors = data.map(() => ({}));
			const invalidPages = new Set();
			let invalidCount = 0; // Initialize the count of invalid entries
			let totalAmtValue = 0; // Initialize the total sum of OPEN_BALANCE values
			const groupedSumsMap = {};
			const groupedSums = [];
			const validationErrors = [];

			const updatedData = data.map((row, rowIndex) => {
				const updatedRow = { ...row }; // Create a copy of the row object
				const rowErrors = {}; // Initialize row-level errors

				Object.keys(row).forEach((header) => {
					const newValue =
						inputValues[`${rowIndex}-${header}`] || row[header];
					let error = null;

					if (header === 'customer_code') {
						if (!isValidNumber(newValue)) {
							error = 'Invalid number input';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'inv_date') {
						if (!isValidDate(newValue)) {
							error = 'Invalid date format (DD-MM-YYYY)';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'open_balance') {
						if (!isValidNumber(newValue)) {
							error = 'Invalid number input';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'customer_gstin') {
						if (!isValidVarchar(newValue)) {
							error = 'Invalid VarChar format';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'credit_period') {
						if (!isValidNumber(newValue)) {
							error = 'Invalid number input';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'sub_total') {
						if (!isValidNumber(newValue)) {
							error = 'Invalid number input';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'conversion_rate') {
						if (!isValidNumber(newValue)) {
							error = 'Invalid number input';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'discount') {
						// Allow blank string or null as valid values
						if (
							newValue !== '' &&
							newValue !== ' ' &&
							newValue !== null &&
							!isValidNumber(newValue)
						) {
							error = 'Invalid number input';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'cgst') {
						if (!isValidNumber(newValue)) {
							error = 'Invalid number input';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'sgst') {
						if (!isValidNumber(newValue)) {
							error = 'Invalid number input';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'igst') {
						if (!isValidNumber(newValue)) {
							error = 'Invalid number input';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'total_amt') {
						if (!isValidNumber(newValue)) {
							error = 'Invalid number input';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'due_amt') {
						if (!isValidNumber(newValue)) {
							error = 'Invalid number input';
							invalidCount++; // Increment the count of invalid entries
						} else {
							// Group and sum by customer_code
							const customerCode = row['customer_code'];
							const customerGstin = row['customer_gstin'];
							const dueAmt = parseFloat(newValue);

							if (!isNaN(dueAmt)) {
								const groupKey = `${customerCode}-${customerGstin}`;
								if (!groupedSumsMap[groupKey]) {
									groupedSumsMap[groupKey] = {
										customer_code: customerCode,
										customer_gstin: customerGstin,
										due_amt: 0,
										error: "",
										firstOpeningVal: 0
									}
								}
								groupedSumsMap[groupKey].due_amt += dueAmt;
							}
						}
					} else if (header === 'customer_shipping_address') {
						// Allow blank string or null as valid values
						if (
							newValue !== '' &&
							newValue !== ' ' &&
							newValue !== null &&
							!isValidText(newValue)
						) {
							error = 'Invalid text input';
							invalidCount++; // Increment the count of invalid entries
						}
					} else if (header === 'customer_billing_address') {
						// Allow blank string or null as valid values
						if (
							newValue !== '' &&
							newValue !== ' ' &&
							newValue !== null &&
							!isValidText(newValue)
						) {
							error = 'Invalid text input';
						}
					} else {
						if (!isValidText(newValue)) {
							error = 'Invalid text input';
							invalidCount++; // Increment the count of invalid entries
						}
					}

					rowErrors[header] = error; // Set the error for this header

					// Update the value in the updatedRow
					updatedRow[header] = newValue;
				});

				newErrors[rowIndex] = rowErrors; // Set the row-level errors

				// If there are errors in this row, mark the page as having errors
				if (Object.values(rowErrors).some((error) => error !== null)) {
					invalidPages.add(
						Math.ceil((startIndex + rowIndex + 1) / itemsPerPage)
					);
				}

				// Calculate the total sum of OPEN_BALANCE values
				// const totalAmt = parseFloat(row['due_amt']);
				// console.log({ totalAmt });
				// if (!isNaN(totalAmt)) {
				// 	totalAmtValue += totalAmt;
				// }

				return updatedRow;
			});

			for (const key in groupedSumsMap) {
				groupedSums.push(groupedSumsMap[key]);
			}

			// Validate groupedSums against apiCustomerData
			groupedSums.forEach((group) => {
				const { customer_code, customer_gstin, due_amt } = group;
				console.log({ customer_code, customer_gstin, due_amt });
				const dueAmtParsed = parseFloat(due_amt);
				const customer1 = apiCustomerListData?.find((cust) => {
					return String(cust.customer_code) === String(customer_code)
				}
				);
				if (customer1) {
					const firstOpeningVal = parseFloat(customer1.first_opening_val);
					if (firstOpeningVal < dueAmtParsed) {
						// group.error = `Due amount (${due_amt}) should be less than first opening value (${firstOpeningVal})`;
						group.error = `Some of invoice can't be higher then the customer opening balance`;
					} else {
						group.error = '';
					}
					group.firstOpeningVal = firstOpeningVal;
				}
			});

			// Update the data state with the modified data
			setData(updatedData);
			setHasValidationErrors(invalidCount > 0);
			setErrors(newErrors);
			setErrorValue(invalidCount); // Display the count of invalid entries
			setRowValidationErrors(newErrors); // Set row-level validation errors
			setPagesWithErrors(Array.from(invalidPages)); // Update the pagesWithErrors state
			setTotalOpenBalance(totalAmtValue);
			setGroupedSums(groupedSums); // Update the groupedSums state
			// Initialize inputValues with the current page's data
			const initialInputValues = {};
			currentData.forEach((row, rowIndex) => {
				Object.keys(row).forEach((header) => {
					initialInputValues[`${rowIndex}-${header}`] = row[header];
				});
			});
			setInputValues(initialInputValues);
		} else {
			setHasValidationErrors(false);
			setRowValidationErrors([]); // Clear row-level validation errors
			setInputValues({});
		}

		setEditing(!editing);
	}, [
		editing,
		data,
		inputValues,
		setTotalOpenBalance,
		setGroupedSums,
		setErrorValue,
		setData,
	]);

	const handleDeleteRow = (rowIndex) => {
		// Create a copy of the data array and remove the row at the specified index
		const newData = [...data];
		newData.splice(startIndex + rowIndex, 1);
		setData(newData);
	};

	const handleInputChange = (rowIndex, header, newValue) => {
		let error = null;
		if (header === 'customer_code') {
			if (!isValidNumber(newValue)) {
				error = 'Invalid number input';
			}
		} else if (header === 'inv_date') {
			if (!isValidDate(newValue)) {
				error = 'Invalid date format (DD-MM-YYYY)';
			}
		} else if (header === 'open_balance') {
			if (!isValidNumber(newValue)) {
				error = 'Invalid number input';
			}
		} else if (header === 'customer_gstin') {
			if (!isValidVarchar(newValue)) {
				error = 'Invalid VarChar format';
			}
		} else if (header === 'credit_period') {
			if (!isValidNumber(newValue)) {
				error = 'Invalid number input';
			}
		} else if (header === 'sub_total') {
			if (!isValidNumber(newValue)) {
				error = 'Invalid number input';
			}
		} else if (header === 'conversion_rate') {
			if (!isValidNumber(newValue)) {
				error = 'Invalid number input';
			}
		} else if (header === 'discount') {
			if (
				newValue !== '' &&
				newValue !== ' ' &&
				newValue !== null &&
				!isValidNumber(newValue)
			) {
				error = 'Invalid number input';
			}
		} else if (header === 'cgst') {
			if (!isValidNumber(newValue)) {
				error = 'Invalid number input';
			}
		} else if (header === 'sgst') {
			if (!isValidNumber(newValue)) {
				error = 'Invalid number input';
			}
		} else if (header === 'igst') {
			if (!isValidNumber(newValue)) {
				error = 'Invalid number input';
			}
		} else if (header === 'total_amt') {
			if (!isValidNumber(newValue)) {
				error = 'Invalid number input';
			}
		} else if (header === 'due_amt') {
			if (!isValidNumber(newValue)) {
				error = 'Invalid number input';
			}
		} else if (header === 'customer_shipping_address') {
			// Allow blank string or null as valid values
			if (
				newValue !== '' &&
				newValue !== ' ' &&
				newValue !== null &&
				!isValidText(newValue)
			) {
				error = 'Invalid text input';
			}
		} else if (header === 'customer_billing_address') {
			// Allow blank string or null as valid values
			if (
				newValue !== '' &&
				newValue !== ' ' &&
				newValue !== null &&
				!isValidText(newValue)
			) {
				error = 'Invalid text input';
			}
		} else {
			if (!isValidText(newValue)) {
				error = 'Invalid text input';
			}
		}
		// Update the input values state
		setInputValues((prevInputValues) => ({
			...prevInputValues,
			[`${rowIndex}-${header}`]: newValue,
		}));

		// Update the data state with the new value if it's valid
		if (!error) {
			const updatedData = [...data];
			updatedData[startIndex + rowIndex][header] = newValue;
			setData(updatedData);
		}

		// Recalculate the total open balance
		calculateTotalAmt();
	};

	const handleJumpPageChange = (event) => {
		const value = event.target.value;
		setJumpPage(value);
	};

	const jumpToPage = () => {
		const pageNumber = parseInt(jumpPage, 10);
		if (isNaN(pageNumber) || pageNumber < 1 || pageNumber > totalPages) {
			toast({
				title: 'Invalid page number',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			return;
		}
		// Check if there is no more data available beyond the last page
		if (pageNumber > totalPages) {
			toast({
				title: 'No more data available',
				status: 'info',
				duration: 3000,
				isClosable: true,
			});
			return;
		}
		onPageChange(pageNumber);
	};

	const newData = data.map((item) => {
		// Create a new object with the existing properties
		const newItem = {
			// Add new properties here
			postinDate: '',
			gl: '',
			subgl: `${item.customer_code}`,
			quantity: 0,
			rate: 0,
			amount: item.open_balance,
			storageLocation: 0,
			inv_no: item.inv_no,
			credit_period: item.credit_period,
			inv_date: item.inv_date,
			sub_total: item.sub_total,
			conversion_rate: item.conversion_rate,
			currency_name: item.currency_name,
			discount: item.discount,
			cgst: item.cgst,
			sgst: item.sgst,
			igst: item.igst,
			total_amt: item.total_amt,
			due_amt: item.due_amt,
			customer_billing_address: item.customer_billing_address,
			customer_shipping_address: item.customer_shipping_address,
			// ... add more new keys as needed ...
		};

		return newItem;
	});

	const uploadItem = async (e) => {
		e.preventDefault();
		let formData = new FormData();
		formData.append('company_id', companyId);
		formData.append('location_id', selectedLocation);
		formData.append('branch_id', selectedBranch);
		formData.append('user_id', userId);
		formData.append('data', JSON.stringify(newData));

		try {
			setIsLoading(true);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}customer-invoice.php`,
				{
					method: 'POST',
					body: formData,
				}
			);

			if (response.ok) {
				setIsLoading(false);
				const responseData = await response.json();
				toastCall(responseData);
				setUploadMessageExcel(responseData.data);
				setUploadMessageExcel(responseData.data);
				setData([]);
				setEditing(false);
			} else {
				// Handle non-successful response (e.g., HTTP error)
				const responseData = await response.json(); // Parse error response as JSON
				toastCall(responseData);
				setUploadMessageExcel(responseData.data);
				setIsLoading(false);
				setEditing(false);
			}
		} catch (error) {
			setIsLoading(false);
			setEditing(false);
		}
	};

	function generateCSVData(demoXlSX) {
		const headers = Object.keys(demoXlSX[0]); // Get the keys from the first object as headers
		const headerRow = headers.join(',') + '\n'; // CSV header row

		const rows = demoXlSX
			.map((item) => {
				const values = headers.map((header) => item[header]);
				return values.join(',');
			})
			.join('\n');

		return headerRow + rows;
	}

	const uploadMessageExcelDownload = () => {
		if (!uploadMessageExcel || uploadMessageExcel.length === 0) {
			alert('No data to download.');
			return; // Exit the function early if there's no data.
		}
		const uploadedData = uploadMessageExcel?.map((items) => ({
			...items,
		}));
		const csvData = generateCSVData(uploadedData);
		const blob = new Blob([csvData], {
			type: 'text/csv;charset=utf-8',
		});
		saveAs(blob, `Customer-Invoice-Upload-Log.csv`);
	};

	return (
		<>
			<form
				style={{
					width: '100%',
				}}
				onSubmit={uploadItem}>
				<Box width='100%' mt='50px'>
					{currentData?.length > 0 && (
						<>
							<Box
								display='flex'
								alignItems='flex-end'
								justifyContent='flex-end'
								mb='15px'>
								<Button
									borderRadius='10px'
									border='1px solid #003060'
									p='20px 30px'
									fontSize='15px'
									background='none'
									color='mainBlueColor'
									onClick={handleEdit}>
									{editing ? 'Save' : 'Validate'}
								</Button>
							</Box>

							<Box
								width='100%'
								overflowX='scroll'
								maxH='500px'
								sx={{
									'& .table_bg': {
										color: 'black',
										textAlign: 'center',
									},
									'& .table_bg:first-of-type': {
										textTransform: 'uppercase',
										textAlign: 'center',
										minHeight: '50px',
										backgroundColor: `${editing
											? 'red'
											: 'var(--chakra-colors-tableSkyBlueLightColor)'
											}`,
										fontWeight: '600',
										color: 'var(--chakra-colors-mainBlueColor)',
									},
									'& .table_bg input': {
										textAlign: 'center',
										fontSize: '14px',
										border: '1px solid var(--chakra-colors-textLightGrayColor)',
										height: '40px',
									},
									'& .table_bg:first-of-type input': {
										textTransform: 'uppercase',
										textAlign: 'center',
										minHeight: '50px',
										backgroundColor: `${editing
											? 'var(--chakra-colors-mainBlueColor)'
											: 'var(--chakra-colors-tableSkyBlueLightColor)'
											}`,
										fontWeight: '600',
										color: 'white',
										border: 'none',
										pointerEvents: 'none',
									},
								}}>
								{/* Table Header */}
								<Box className='table_bg' display='flex'>
									{Object.keys(currentData[0]).map(
										(header) => (
											<Box
												key={header}
												display='flex'
												alignItems='center'
												justifyContent='center'
												border='1px solid #ccc'
												padding={
													editing ? '5px' : '10px'
												}
												minWidth='400px'
												backgroundColor={
													editing
														? 'white'
														: 'var(--chakra-colors-tableSkyBlueLightColor)'
												}>
												{header}
											</Box>
										)
									)}
								</Box>

								{/* Table Rows */}
								{/* {currentData.map((row, rowIndex) => ( */}
								{data.map((row, rowIndex) => (
									<Box
										className='table_bg'
										key={rowIndex}
										style={{
											display: 'flex',
											marginBottom: '10px',
										}}>
										{/* // Inside the map function for table
										rows */}
										{Object.keys(row).map((header) => (
											<Box
												key={header}
												display='flex'
												flexDirection='column'
												alignItems='center'
												justifyContent='center'
												border='1px solid #ccc'
												padding={
													editing ? '5px' : '10px'
												}
												minWidth='400px'
												backgroundColor={
													editing
														? 'white'
														: 'var(--chakra-colors-tableSkyBlueLightColor)'
												}>
												{editing ? (
													<>
														<Input
															className='input_box'
															type='text'
															value={
																inputValues[
																`${rowIndex}-${header}`
																] || row[header]
															}
															onChange={(e) =>
																handleInputChange(
																	rowIndex,
																	header,
																	e.target
																		.value
																)
															}
														/>
														{rowValidationErrors[
															rowIndex
														] &&
															rowValidationErrors[
															rowIndex
															][header] && (
																<Box
																	fontSize='1.2rem'
																	fontWeight='600'
																	color='red'>
																	{
																		rowValidationErrors[
																		rowIndex
																		][
																		header
																		]
																	}
																</Box>
															)}
													</>
												) : (
													row[header]
												)}
											</Box>
										))}
										<Box
											display='flex'
											alignItems='center'
											justifyContent='center'
											minWidth='50px'>
											<Button
												size='sm'
												background='none'
												_hover={{ bg: 'none' }}
												_active={{ bg: 'none' }}
												_focus={{ bg: 'none' }}
												onClick={() =>
													handleDeleteRow(rowIndex)
												}>
												<i className='fa-solid fa-trash fa-2x'></i>
											</Button>
										</Box>
									</Box>
								))}
							</Box>
						</>
					)}
				</Box>
			</form>

			{uploadMessageExcel !== undefined && (
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
					borderRadius='10px'
					bg='#dbe5ee'
					mt='20px'
					p='15px'
					w='100%'>
					<Box>
						<Text fontWeight='600' mb='10px'>
							Successfully Uploaded :
						</Text>
						<Text fontWeight='600' mb='10px'>
							Upload Faild :
						</Text>
					</Box>
					<Button
						onClick={uploadMessageExcelDownload}
						m='10px 0px'
						bgGradient='linear(180deg, #2267A2 0%, #0D4675 100%)'
						boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
						borderRadius='10px'
						p='20px 40px'
						fontSize='1.6rem'
						color='white'
						_hover={{
							bgGradient:
								'linear(180deg, #2267A2 0%, #0D4675 100%)',
						}}
						_active={{
							bgGradient:
								'linear(180deg, #2267A2 0%, #0D4675 100%)',
						}}
						_focus={{
							bgGradient:
								'linear(180deg, #2267A2 0%, #0D4675 100%)',
						}}>
						<i className='fa-solid fa-circle-down'></i>{' '}
						<Box as='span' ml='10px'>
							Download Log
						</Box>
					</Button>
				</Box>
			)}
		</>
	);
};

export default CustomerInvoicePaginatedData;
