import React, { useState, useCallback, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Image, Input, Text, Heading, useToast, Tabs, TabList, Tab, TabPanels, TabPanel, Switch, Alert, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import uploadIcon from '../../../assets/upload_icon.png';
import AutoTypeText from '../multistepform/autotype';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import VendorInvoicePaginatedData from './vendorInvoicePaginatedData';

const VendorInvoice = () => {
	const toast = useToast();
	const [data, setData] = useState([]);
	const [editing, setEditing] = useState(false);
	const companyId = useSelector((state) => state.companyId);
	const userId = useSelector((state) => state.userId);
	const selectedLocation = useSelector((state) => state.selectedLocation);
	const selectedBranch = useSelector((state) => state.selectedBranch);
	const locationCode = useSelector((state) => state.locationCode);
	const [uploadMessageExcel, setUploadMessageExcel] = useState(undefined); // Initialize as undefined
	const [errors, setErrors] = useState([]);
	const [hasValidationErrors, setHasValidationErrors] = useState(false);
	const [errorValue, setErrorValue] = useState();
	const [totalOpenBalance, setTotalOpenBalance] = useState(0);
	const [vendorList, setVendorList] = useState();
	const [pagesWithErrors, setPagesWithErrors] = useState(new Set());
	const [currentPage, setCurrentPage] = useState(1); // Current page
	const itemsPerPage = 50; // Number of items to display per page
	const [handleToggle, setHandleToggle] = useState(true);
	const [groupedSums, setGroupedSums] = useState([]);

	console.log('🔵', {groupedSums})

	function toastCallError(data) {
		return toast({
			title: data.toString(),
			status: 'error',
			duration: 3000,
			isClosable: true,
		});
	}

	const handleFilter = () => {
		setHandleToggle(!handleToggle);
	}

	function toastCallApiErrorList() {
		return toast({
			title: 'No List Found',
			status: 'error',
			duration: 3000,
			isClosable: true,
		});
	}

	function bigPrint(number) {
		const numberAsString = number.toString();
		return numberAsString;
	}

	function formatDateToDdMmYy(date) {
		const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if needed
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (add 1 as months are zero-indexed) and pad with leading zero if needed
		const year = String(date.getFullYear()).slice(-2); // Get the last 2 digits of the year

		return `${day}-${month}-${year}`;
	}
	const currentDate = new Date();
	const formattedDate = formatDateToDdMmYy(currentDate);

	useEffect(() => {
		// Calculate the errorValue based on the errors state
		const newErrorValue = errors.reduce((acc, rowErrors) => {
			return (
				acc +
				Object.values(rowErrors).filter((error) => error !== null)
					.length
			);
		}, 0);

		setErrorValue(newErrorValue);
	}, [errors]); // Watch for changes in the errors state

	useEffect(() => {
		const glData = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}api-vendor-list-balance.php?company_id=${companyId}&branch_id=${selectedBranch}&location_id=${selectedLocation}&user_id=${userId}`,
					{
						method: 'GET',
					}
				);
				if (response.ok) {
					const data = await response.json();
					setVendorList(data.message);
				} else {
					const data = await response.json();
					toastCallError(data);
				}
			} catch {
				console.log('error');
			}
		};
		glData();
	}, [selectedBranch]);

	const handleFileDrop = useCallback((acceptedFiles) => {
		const file = acceptedFiles[0];
		const reader = new FileReader();

		reader.onload = (event) => {
			const data = new Uint8Array(event.target.result);
			const workbook = XLSX.read(data, { type: 'array' });

			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];

			const excelData = XLSX.utils.sheet_to_json(worksheet, {
				header: 1,
			});

			const headers = excelData[0].map((header) => header.trim());
			const formattedData = excelData.slice(1).map((row) => {
				const rowData = {};
				row.forEach((cell, columnIndex) => {
					const header = headers[columnIndex];
					rowData[header] = cell;

					// Check if the column is a date column by header name
					if (
						header === 'inv_date' ||
						header === 'inv_posting_date' ||
						header === 'inv_due_date'
					) {
						// Convert the numeric date to a Date object
						const numericDate = parseFloat(cell);
						const millisecondsSinceEpoch =
							(numericDate - 25569) * 86400 * 1000;
						const dateObject = new Date(millisecondsSinceEpoch);

						// Format the date in dd-mm-yyyy format
						const day = String(dateObject.getDate()).padStart(
							2,
							'0'
						);
						const month = String(
							dateObject.getMonth() + 1
						).padStart(2, '0');
						const year = dateObject.getFullYear();

						rowData[header] = `${day}-${month}-${year}`;
					}
				});
				return rowData;
			});

			// Fill missing keys with empty strings
			const emptyRow = Object.fromEntries(
				headers.map((header) => [header, ''])
			);
			const dataWithEmptyKeys = formattedData.map((row) => ({
				...emptyRow,
				...row,
			}));

			// Filter out blank rows
			const nonBlankRows = dataWithEmptyKeys.filter((row) => {
				return Object.values(row).some(
					(cellValue) => cellValue !== null && cellValue !== ''
				);
			});

			setData(nonBlankRows);
			setUploadMessageExcel(undefined);
		};

		reader.readAsArrayBuffer(file);
	});

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: handleFileDrop,
		accept: '.csv',
	});

	const demoXlSX = vendorList;
	function generateCSVData(demoXlSX) {
		const headers = Object.keys(demoXlSX[0]); // Get the keys from the first object as headers
		const headerRow = headers.join(',') + '\n'; // CSV header row

		const rows = demoXlSX
			.map((item) => {
				const values = headers.map((header) => item[header]);
				return values.join(',');
			})
			.join('\n');

		return headerRow + rows;
	}

	const handleDownload = () => {
		if (demoXlSX !== undefined) {
			const updatedData = demoXlSX?.map((asset) => ({
				vendor_code: asset.vendor_code,
				vendor_gstin: asset.vendor_gstin,
				trade_name: asset.trade_name,
				inv_no: '',
				inv_date: '',
				inv_posting_date: '',
				inv_due_date: '',
				inv_due_days: '',
				inv_total: '',
				inv_sub_total: '',
				cgst: '',
				sgst: '',
				igst: '',
				dueAmt: '',
				tds: '',
			}));
			const csvData = generateCSVData(updatedData);
			const blob = new Blob([csvData], {
				type: 'text/csv;charset=utf-8',
			});
			saveAs(blob, `Vendor-Invoice-${locationCode}-${formattedDate}.csv`);
		} else {
			toastCallApiErrorList();
		}
	};

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	return (
		<Box
			width='100%'
			height='100%'
			display='flex'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'>
			<Box w='100%' display='flex' justifyContent='space-around'>
				<Box
					width='38%'
					display='flex'
					justifyContent='space-around'
					alignItems='center'>
					<Box width='400px'>
						<Box
							border='2px dashed var(--chakra-colors-bgGraycolor)'
							p='15px'
							borderRadius='20px'
							opacity={editing ? '0.5' : '1'}
							pointerEvents={editing ? 'none' : 'painted'}>
							<Box
								{...getRootProps()}
								bg='var(--chakra-colors-mainBlueColor)'
								width='100%'
								height='100%'
								borderRadius='20px'
								display='flex'
								justifyContent='center'
								alignItems='center'
								color='white'
								padding='50px 0px'
								cursor='grab'>
								<Input {...getInputProps()} />
								{isDragActive ? (
									<Box
										display='flex'
										flexDirection='column'
										justifyContent='center'
										alignItems='center'>
										<Image
											src={uploadIcon}
											alt='upload icon'
											height='100px'
											width='100px'
										/>
										<Text>Drop the Excel file here...</Text>
									</Box>
								) : (
									<Box
										display='flex'
										flexDirection='column'
										justifyContent='center'
										alignItems='center'>
										<Image
											src={uploadIcon}
											alt='upload icon'
											height='100px'
											width='100px'
										/>
										<Text>Drag & Drop your files</Text>
									</Box>
								)}
							</Box>
						</Box>
						<Box
							display='flex'
							width='100%'
							justifyContent='center'
							gap='15px'
							paddingTop='45px'>
							<Button
								borderRadius='10px'
								border='1px solid #003060'
								p='20px 30px'
								width='50%'
								fontSize='15px'
								background='none'
								color='mainBlueColor'
								onClick={handleDownload}
								cursor='pointer'>
								Download Sample
							</Button>
						</Box>
					</Box>
				</Box>
				<Box
					width='58%'
					boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
					borderRadius='15px'
					padding='15px'>
					{/* **************************************** */}
					<Tabs>
						<TabList>
							<Tab>Data Summary</Tab>
							<Tab>Vendor Invoice</Tab>
						</TabList>

						<TabPanels>
							<TabPanel sx={{ alignItems: 'baseline !important', minHeight: '200px !important', boxShadow: 'none !important' }}>
								<Box>
									<Heading
										mb='15px'
										color='var(--chakra-colors-mainBlueColor)'
										fontSize='28px'>
										Data Summary :{' '}
									</Heading>
									<Text
										fontSize='1.7rem'
										fontWeight='600'
										marginBottom='10px'
										color='green'>
										Total lines : {data?.length}
									</Text>
									<Text
										fontSize='1.7rem'
										fontWeight='600'
										marginBottom='10px'
										color='red'>
										Total Error : {errorValue}
									</Text>
									{/* Display the total OPEN_BALANCE */}
									<Text
										fontSize='1.7rem'
										fontWeight='600'
										color='mainBlueColor'
										marginBottom='5px'>
										Balance: {bigPrint(totalOpenBalance)}
									</Text>
									<Box mt='10px' mb='10px'>
										<Box
											fontSize='1.7rem'
											fontWeight='600'
											color='mainBlueColor'>
											Pages with errors:
										</Box>

										{Array.from(pagesWithErrors).join(', ')}
									</Box>
									<Box>
										<Text
											fontSize='1.7rem'
											fontWeight='600'
											color='mainBlueColor'
											marginBottom='5px'>
											Data Review Report -
										</Text>
										<AutoTypeText />
									</Box>
								</Box>
							</TabPanel>
							<TabPanel sx={{ alignItems: 'baseline !important', minHeight: '100% !important', boxShadow: 'none !important', maxHeight: "350px", overflowY: "scroll" }}>
								<Box width="100%">
									<Text fontSize='1rem'>
										Filter: <Switch onChange={handleFilter} isChecked={handleToggle} />
									</Text>
									{groupedSums.length === 0 ? <Alert status='error' width={'100%'} fontSize={10} py={0}>Upload the invoice to see data!</Alert> : <TableContainer>
										<Table variant='simple' size='lg' width='100%' >
											<Thead>
												<Tr>
													<Th>Vendor Code</Th>
													<Th>GST</Th>
													<Th isNumeric>Total Invoice Amount</Th>
													<Th isNumeric>Vendor Opening</Th>
												</Tr>
											</Thead>
											<Tbody>
												{
													groupedSums && groupedSums.map((group, index) => (
														<>
															{handleToggle ? group?.error &&
																<>
																	<Tr bg={group?.error ? 'red.50' : ''} key={index}>
																		<Td>{group.vendor_code}</Td>
																		<Td>{group.vendor_gstin}</Td>
																		<Td isNumeric>{group.due_amt}</Td>
																		<Td isNumeric>{group.firstOpeningVal}</Td>
																	</Tr>
																</> : <Tr bg={group?.error ? 'red.50' : ''} key={index}>
																<Td>{group.vendor_code}</Td>
																<Td>{group.vendor_gstin}</Td>
																<Td isNumeric>{group.due_amt}</Td>
																<Td isNumeric>{group.firstOpeningVal}</Td>
															</Tr>
															}
															<p style={{ fontSize: '10px', color: 'red' }}>{group?.error}</p>
														</>
													))
												}
											</Tbody>
										</Table>
									</TableContainer>}
									{/* {JSON.stringify(groupedSums, null, 2)} */}
								</Box>
							</TabPanel>
						</TabPanels>
					</Tabs>
					{/* **************************************** */}
				</Box>
			</Box>
			<VendorInvoicePaginatedData
				data={data}
				setData={setData}
				currentPage={currentPage}
				itemsPerPage={itemsPerPage}
				editing={editing}
				setEditing={setEditing}
				onPageChange={handlePageChange}
				setTotalOpenBalance={setTotalOpenBalance}
				setGroupedSums={setGroupedSums}
				setErrorValue={setErrorValue}
				errors={errors}
				setErrors={setErrors}
				hasValidationErrors={hasValidationErrors}
				setHasValidationErrors={setHasValidationErrors}
				setPagesWithErrors={setPagesWithErrors}
				uploadMessageExcel={uploadMessageExcel}
				setUploadMessageExcel={setUploadMessageExcel}
				vendorList={vendorList}
			/>
		</Box>
	);
};

export default VendorInvoice;
